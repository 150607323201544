import ErrorList from "../ErrorList";
import { useState, useEffect } from "react";

export default function ExpensesForm({
  handleFormChange,
  handleSubmit,
  informError,
  disabled,
  expense,
  saved,
  error,
}) {
  const [textareaHeight, setTextareaHeight] = useState("auto");

  const handleTextareaInput = (event) => {
    const { scrollHeight, clientHeight } = event.target;
    const newHeight = Math.max(scrollHeight, clientHeight);
    setTextareaHeight(`${newHeight}px`);
  };

  useEffect(() => {
    const textarea = document.getElementById("notesInput");
    if (textarea) {
      const { scrollHeight, clientHeight } = textarea;
      const newHeight = Math.max(scrollHeight, clientHeight);
      setTextareaHeight(`${newHeight}px`);
    }
  }, [expense.notes]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="form-group">
        <label htmlFor="titleInput">Title<strong className="text-danger">*</strong></label>
        <input
          id="titleInput"
          className="form-control"
          type="text"
          name="title"
          value={expense.title}
          disabled={disabled}
          onChange={handleFormChange}
        />
      </div>

      <div className="form-group">
        <label htmlFor="valueInput">Value</label>
        <input
          id="valueInput"
          className="form-control"
          type="number"
          name="value"
          value={expense.value}
          disabled={disabled}
          onChange={handleFormChange}
        />
      </div>

      <div className="form-group">
        <label htmlFor="notesInput">Notes</label>
        <textarea
          id="notesInput"
          className="form-control"
          name="notes"
          value={expense.notes}
          style={{ height: textareaHeight }}
          disabled={disabled}
          onChange={(e) => {
            handleFormChange(e);
            handleTextareaInput(e);
          }}
        ></textarea>

        <div className="form-check">
          <input
            id="paydInput"
            className="form-check-input"
            type="checkbox"
            name="payd"
            checked={expense.payd}
            disabled={disabled}
            onChange={handleFormChange}
          />
          <label htmlFor="paydInput">Paid</label>
        </div>
      </div>

      {informError && <ErrorList errors={[error]}></ErrorList>}
      {!saved && (
        <button type="submit" className="btn btn-light" disabled={disabled}>
          <i className="bi bi-save"></i> Save
        </button>
      )}
    </form>
  );
}
